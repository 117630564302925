<template>
  <div
    :style="{
      backgroundImage: `url(${require('@/assets/kelor.png')})`,
      backgroundRepeat: 'repeat',
      backgroundSize: '15vw',
      // backgroundSize: '250px',
    }"
  >
    <div class="search-banner has-bg">
      <div
        class="bg-cover"
        data-paroller="true"
        data-paroller-factor="0.5"
        data-paroller-factor-xs="0.01"
        style="
          background-image: url('../assets/img/bg-home.jpg');
          overflow: hidden;
          border-radius: 0px 0px 30px 30px;
          height: 65vh;
        "
      ></div>

      <div
        class="container"
        style="padding-bottom: 0px; margin-bottom: 0px; text-align: center"
      >
        <h1>Selamat Datang di</h1>
        <img
          src="../../assets/pakagali-color.png"
          alt="logo"
          style="width: 40%; margin-bottom: 20px"
        />
        <h5 class="text-center">Cek Status Retribusi Anda</h5>
        <div class="input-group">
          <input
            v-model="search"
            type="text"
            class="form-control form-control-lg"
            :class="{
              'is-invalid': this.searchError,
            }"
            placeholder="Masukkan NPWRD/NIK/NIB"
          />
          <span class="input-group-append">
            <button
              v-if="foundData"
              class="btn btn-lg"
              @click="resetData"
              variant="primary"
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              class="btn btn-lg"
              @click="fetchData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              <i v-else class="fa fa-search"></i>
            </button>
            <button class="btn btn-lg" @click="toggleCamera" variant="primary">
              <i class="fa fa-qrcode"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="container" v-if="showQrCode">
        <div
          class="row m-1 p-5"
          style="border-radius: 10px; background-color: #2b2b2b"
        >
          <StreamBarcodeReader
            style="margin: auto; border-radius: 10px; overflow: hidden"
            ref="scanner"
            @decode="onDecode"
            @loaded="onLoaded"
          ></StreamBarcodeReader>
        </div>
      </div>

      <div class="container" style="margin-top: -10px" v-show="foundData">
        <div class="card mb-1 text-dark shadow" style="border-radius: 15px">
          <div style="text-align: center; padding: 20px">
            <h5>Informasi Pelanggan</h5>
          </div>
          <div class="alert f-s-13">
            <div class="row">
              <div class="col-md-12 mb-2">
                <div class="card" style="border-radius: 10px; padding: 15px">
                  <div class="row mb-2">
                    <div class="col-6">Nama</div>
                    <div class="col-6 text-right">
                      <strong>
                        <LazyLoad
                          :data-to-display="detail.customer_name"
                        ></LazyLoad>
                      </strong>
                    </div>
                  </div>
                  <hr style="margin: 0px 0px 10px 0px" />
                  <div class="row">
                    <div class="col-6">NPWRD</div>
                    <div class="col-6 text-right">
                      <strong>
                        <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <label class="mb-0">Jenis Kegiatan : </label>
                <strong>
                  <LazyLoad :data-to-display="detail.category_name"></LazyLoad>
                </strong>
              </div>
              <div class="col-md-12" style="margin-bottom: -15px">
                <label class="mb-0">Satuan : </label>
                <strong>
                  <LazyLoad
                    :data-to-display="this.currencyFormat(detail.cost)"
                  ></LazyLoad>
                </strong>
              </div>
            </div>
          </div>
          <div class="container" style="padding: 20px">
            <div class="row">
              <div class="col-md-4 mb-2">
                <button
                  class="btn btn-warning btn-block btn-sm"
                  @click="showTab = 1"
                >
                  Status Transaksi
                </button>
              </div>
              <div class="col-md-4 mb-2">
                <button
                  class="btn btn-warning btn-block btn-sm"
                  @click="showTab = 2"
                >
                  Riwayat Transaksi
                </button>
              </div>
              <div class="col-md-4 mb-2">
                <button
                  class="btn btn-warning btn-block btn-sm"
                  @click="
                    drawCanvas();
                    showTab = 3;
                  "
                >
                  Kartu Pelanggan
                </button>
              </div>
              <div class="col-md-12" v-show="showTab == 1">
                <div class="bg-light rounded p-5">
                  <table class="table table-borderless f-s-12">
                    <tr>
                      <td class="text-center">Jan</td>
                      <td class="text-center">Feb</td>
                      <td class="text-center">Mar</td>
                      <td class="text-center">Apr</td>
                      <td class="text-center">Mei</td>
                      <td class="text-center">Jun</td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.jan == '1',
                            'bg-danger text-light':
                              payment.jan == '0' || payment.jan == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.jan == '1',
                              'fa fa-times':
                                payment.jan == '0' || payment.jan == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.feb == '1',
                            'bg-danger text-light':
                              payment.feb == '0' || payment.feb == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.feb == '1',
                              'fa fa-times':
                                payment.feb == '0' || payment.feb == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.mar == '1',
                            'bg-danger text-light':
                              payment.mar == '0' || payment.mar == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.mar == '1',
                              'fa fa-times':
                                payment.mar == '0' || payment.mar == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.apr == '1',
                            'bg-danger text-light':
                              payment.apr == '0' || payment.apr == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.apr == '1',
                              'fa fa-times':
                                payment.apr == '0' || payment.apr == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.mei == '1',
                            'bg-danger text-light':
                              payment.mei == '0' || payment.mei == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.mei == '1',
                              'fa fa-times':
                                payment.mei == '0' || payment.mei == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.jun == '1',
                            'bg-danger text-light':
                              payment.jun == '0' || payment.jun == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.jun == '1',
                              'fa fa-times':
                                payment.jun == '0' || payment.jun == null,
                            }"
                          ></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6"></td>
                    </tr>
                    <tr>
                      <td class="text-center">Jul</td>
                      <td class="text-center">Ags</td>
                      <td class="text-center">Sep</td>
                      <td class="text-center">Okt</td>
                      <td class="text-center">Nov</td>
                      <td class="text-center">Des</td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.jul == '1',
                            'bg-danger text-light':
                              payment.jul == '0' || payment.jul == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.jul == '1',
                              'fa fa-times':
                                payment.jul == '0' || payment.jul == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.aug == '1',
                            'bg-danger text-light':
                              payment.aug == '0' || payment.aug == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.aug == '1',
                              'fa fa-times':
                                payment.aug == '0' || payment.aug == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.sep == '1',
                            'bg-danger text-light':
                              payment.sep == '0' || payment.sep == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.sep == '1',
                              'fa fa-times':
                                payment.sep == '0' || payment.sep == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.okt == '1',
                            'bg-danger text-light':
                              payment.okt == '0' || payment.okt == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.okt == '1',
                              'fa fa-times':
                                payment.okt == '0' || payment.okt == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.nov == '1',
                            'bg-danger text-light':
                              payment.nov == '0' || payment.nov == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.nov == '1',
                              'fa fa-times':
                                payment.nov == '0' || payment.nov == null,
                            }"
                          ></i>
                        </a>
                      </td>
                      <td class="text-center">
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-icon btn-circle"
                          :class="{
                            'bg-success text-light': payment.des == '1',
                            'bg-danger text-light':
                              payment.des == '0' || payment.des == null,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-check': payment.des == '1',
                              'fa fa-times':
                                payment.des == '0' || payment.des == null,
                            }"
                          ></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-12" v-show="showTab == 2">
                <div class="bg-light f-s-12 text-dark rounded p-5">
                  <div
                    v-for="history in historyPayment"
                    :key="history.transaction_id"
                    class="d-flex align-items-center m-b-15"
                  >
                    <div class="text-dark">
                      <div>{{ history.additional_details }}</div>
                      <div class="text-dark">
                        {{ indonesianFormat(history.transaction_date) }}
                      </div>
                    </div>
                    <div class="ml-auto text-center">
                      <div class="f-s-13">
                        <span
                          data-animation="number"
                          :data-value="history.payment_amount"
                          >{{ currencyFormat(history.payment_amount) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="showTab == 3">
                <canvas ref="myCanvas" style="display: none"></canvas>
                <img
                  ref="canvasImage"
                  width="100%"
                  style="border-radius: 10px"
                />
                <button
                  class="btn btn-warning btn-block mt-1"
                  @click="downloadImage"
                >
                  Download Kartu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row m-1 p-5 card-menu">
          <div class="row m-1 p-5">
            <div
              class="col-md-12 mb-2 p-3 text-center f-s-14"
              style="color: #3f4244; font-weight: bolder"
            >
              <span class="text-center">MENU</span>
            </div>
            <div class="col-md-4 text-center mb-3 d-none d-md-block">
              <router-link class="text-link" to="/payment">
                <div
                  class="card text-center"
                  style="border-radius: 10px; padding: 20px"
                >
                  <img
                    style="width: 60%; margin: auto"
                    src="../../assets/img/1.png"
                    alt=""
                  />
                  <span class="mt-3 text-dark f-s-14">Bayar Retribusi</span>
                </div>
              </router-link>
            </div>
            <div class="col-4 col-md-4 text-center mb-3 d-md-none">
              <router-link class="text-link" to="/payment">
                <div
                  class="card text-center"
                  style="border-radius: 10px; padding: 10px"
                >
                  <img
                    style="width: 100%; margin: auto"
                    src="../../assets/img/1.png"
                    alt=""
                  />
                </div>
                <span class="mt-3 text-dark f-s-10">Bayar Retribusi</span>
              </router-link>
            </div>
            <div class="col-md-4 text-center mb-3 d-none d-md-block">
              <router-link class="text-link" to="/report">
                <div class="card" style="border-radius: 10px; padding: 20px">
                  <img
                    style="width: 60%; margin: auto"
                    src="../../assets/img/2.png"
                    alt=""
                  />
                  <span class="mt-3 text-dark f-s-14">Lapor Sampah</span>
                </div></router-link
              >
            </div>
            <div class="col-4 col-md-4 text-center mb-3 d-md-none">
              <router-link class="text-link" to="/report">
                <div class="card" style="border-radius: 10px; padding: 10px">
                  <img
                    style="width: 100%; margin: auto"
                    src="../../assets/img/2.png"
                    alt=""
                  />
                </div>
                <span class="mt-3 text-dark f-s-10">Lapor Sampah</span>
              </router-link>
            </div>
            <div class="col-md-4 text-center mb-3 d-none d-md-block">
              <router-link class="text-link" to="/registrasi">
                <div class="card" style="border-radius: 10px; padding: 20px">
                  <img
                    style="width: 60%; margin: auto"
                    src="../../assets/img/3.png"
                    alt=""
                  />
                  <span class="mt-3 text-dark f-s-14">Daftar Mandiri</span>
                </div>
              </router-link>
            </div>
            <div class="col-4 col-md-4 text-center mb-3 d-md-none">
              <router-link class="text-link" to="/registrasi">
                <div class="card" style="border-radius: 10px; padding: 10px">
                  <img
                    style="width: 100%; margin: auto"
                    src="../../assets/img/3.png"
                    alt=""
                  />
                </div>
                <span class="mt-3 text-dark f-s-10">Daftar Mandiri</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footers mt-3" style="border-radius: 30px 30px 0px 0px">
      <div class="container">
        <div class="row">
          <div class="col-md-4" style="padding: 15px">
            <h5>PAKAGALI</h5>
            <span>DLH KOTA PALU</span>
            <br />
          </div>
          <div class="col-md-4" style="padding: 15px">
            <h5>
              <router-link class="text-link text-white" to="/privacy-policy"
                >Privacy Policy</router-link
              >
            </h5>
          </div>
          <div class="col-md-4" style="padding: 15px">
            <h5>Tentang</h5>
            <span>
              Aplikasi PAKAGALI (Pengelolaan Aplikasi Kegiatan Lingkungan)
              merupakan aplikasi yang bisa di akses oleh seluruh masyarakat Kota
              Palu. Aplikasi ini diperuntukkan untuk memeriksa status retribusi
              sampah yang telah dibayar oleh masyarakat Kota Palu.
            </span>
          </div>
        </div>
      </div>
      <div class="copy">Copyright &copy; 2024 DLH KOTA PALU</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import QRCode from "qrcode";

export default {
  components: {
    LazyLoad,
    StreamBarcodeReader,
  },
  data() {
    return {
      isCameraPaused: false,
      showQrCode: false,
      isScrolled: false,
      notImage: false,
      search: "",
      detail: {
        customer_id: "",
        npwrd: "",
        identity_number: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        created_name: "",
      },
      payment: {
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        mei: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        okt: "",
        nov: "",
        des: "",
      },
      historyPayment: [],
      loading: false,
      foundData: false,
      showTab: 1,
      searchError: false,
    };
  },
  computed: {
    rwrtData() {
      return `${this.detail.hamlet} / ${this.detail.neighbourhood}`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    toggleCamera() {
      if (this.showQrCode) {
        this.showQrCode = false;
        this.$refs.scanner.codeReader.stream
          .getTracks()
          .forEach(function (track) {
            track.stop();
          });
      } else {
        this.showQrCode = true;
      }
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`);
      this.search = text;
      this.fetchData();
      this.$refs.scanner.codeReader.stream
        .getTracks()
        .forEach(function (track) {
          track.stop();
        });
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
    fetchData() {
      if (this.search) {
        this.searchError = false;
        axios
          .get("/v1/customers/npwrd/" + this.search)
          .then((response) => {
            for (let key in response.data.data) {
              this.detail[key] = response.data.data[key];
            }
            this.historyPayment = response.data.history;
            this.payment = response.data.payment;
            this.foundData = true;
            this.showQrCode = false;
            if (this.detail.npwrd) {
              this.drawCanvas();
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Info",
              text: "Anda belum terdaftar, silahkan mendaftarkan diri di loket pakagali pada kelurahan masing-masing",
              icon: "info",
              showCancelButton: false,
              showConfirmButton: true,
            });
            for (let key in this.detail) {
              this.detail[key] = "";
            }
            this.foundData = false;
            // setTimeout(() => {
            //   Swal.close();
            //   for (let key in this.detail) {
            //     this.detail[key] = "";
            //   }
            //   this.foundData = false;
            // }, 3000);
            console.log(error);
            this.error = error.message;
          });
      } else {
        this.searchError = true;
      }
    },
    resetData() {
      for (let key in this.detail) {
        this.detail[key] = "";
      }
      this.search = "";
      this.foundData = false;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    paymentStatus(param) {
      if (param == 1) {
        return "<i class='fa fa-check-circle'></i>";
      } else {
        return "Belum Lunas";
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    drawCanvas() {
      const canvas = this.$refs.myCanvas;
      const context = canvas.getContext("2d");
      const dpi = window.devicePixelRatio || 1;

      // Mengatur lebar dan tinggi canvas sesuai dengan dimensi fisik
      canvas.width = 2510 * dpi; // Lebar dalam piksel
      canvas.height = 1476 * dpi; // Tinggi dalam piksel

      context.imageSmoothingQuality = "high";

      // Gambar latar belakang
      context.fillStyle = "#f0f0f0";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const blanko = new Image();
      blanko.src = require("@/assets/blanko.png");

      blanko.onload = () => {
        context.drawImage(blanko, 0, 0, canvas.width, canvas.height);

        const sairaFont = new FontFace(
          "Saira",
          `url(${require("@/assets/font/saira.ttf")})`
        );

        sairaFont.load().then((font) => {
          document.fonts.add(font);

          // Menghasilkan Promise untuk QR code data URL
          const qrCodePromise = QRCode.toDataURL(this.detail.npwrd);

          // Menunggu hingga Promise selesai
          qrCodePromise
            .then((qrCodeUrl) => {
              // Membuat objek Image
              const qrCodeImage = new Image();
              qrCodeImage.src = qrCodeUrl;

              // Menunggu hingga gambar QR code selesai dimuat
              qrCodeImage.onload = () => {
                // Menggambar gambar ke dalam canvas setelah QR code selesai dimuat
                context.drawImage(
                  qrCodeImage,
                  1845 * dpi,
                  650 * dpi,
                  510 * dpi,
                  510 * dpi
                );

                // Fungsi untuk mengatur teks secara otomatis menyesuaikan atau "wrap"
                const wrapText = (text, x, y, maxWidth, lineHeight) => {
                  let words = text.split(" ");
                  let line = "";
                  let yPos = y;

                  for (let i = 0; i < words.length; i++) {
                    let testLine = line + words[i] + " ";
                    let metrics = context.measureText(testLine);
                    let testWidth = metrics.width;

                    if (testWidth > maxWidth && i > 0) {
                      context.fillText(line, x, yPos);
                      line = words[i] + " ";
                      yPos += lineHeight;
                    } else {
                      line = testLine;
                    }
                  }

                  context.fillText(line, x, yPos);
                };

                // Mengatur teks secara otomatis menyesuaikan atau "wrap"
                context.font = `bold ${100 * dpi}px Saira`;
                context.fillStyle = "#F5661A";
                wrapText(
                  this.detail.customer_name,
                  150 * dpi,
                  1070 * dpi,
                  1700 * dpi, // Lebar maksimum untuk wrapping teks
                  120 * dpi // Tinggi baris teks
                );

                context.font = `bold ${250 * dpi}px Saira`;
                context.fillStyle = "#274665";
                wrapText(
                  this.detail.npwrd,
                  150 * dpi,
                  920 * dpi,
                  2200 * dpi, // Lebar maksimum untuk wrapping teks
                  300 * dpi // Tinggi baris teks
                );

                context.font = `bold ${80 * dpi}px Saira`;
                context.fillStyle = "#274665";
                wrapText(
                  this.timeStampFormat(this.detail.created_at),
                  150 * dpi,
                  1335 * dpi,
                  2200 * dpi, // Lebar maksimum untuk wrapping teks
                  90 * dpi // Tinggi baris teks
                );

                const canvasImage = this.$refs.canvasImage;

                const dataURL = canvas.toDataURL("image/jpg");

                canvasImage.src = dataURL;
              };
            })
            .catch((error) => {
              console.error("Gagal menghasilkan QR code:", error);
            });
        });
      };
    },
    downloadImage() {
      const canvas = this.$refs.myCanvas;
      const dataURL = canvas.toDataURL("image/jpeg");

      const downloadLink = document.createElement("a");
      downloadLink.href = dataURL;
      downloadLink.download = "kartu-retribusi-" + this.detail.npwrd + ".jpg";

      downloadLink.click();
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="css" scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}
.text-link {
  text-decoration: none;
}
.headers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  color: #fff;
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
@import "/css/front-end/app.min.css";

.footers {
  /* background-color: #2b2b2b; */
  background-color: #e94f07;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
.card-menu {
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 20vh;
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>